<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateAttendanceAction)"
        @reset.prevent="resetForm"
        v-if="attendanceData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="4">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Attendance
            </h6>
          </b-col>
          <b-col cols="8">
            <b-button
              v-if="$router.name == 'attendance-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'attendance-view',
                  params: { id: attendanceData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'attendance-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'attendance-view',
                  params: { id: attendanceData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'attendance-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste de Présence</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col cols="12" xl="12" md="12">
            <b-card no-body class="invoice-preview-card">
              <b-row class="">
                <b-col cols="12" md="12">
                  <h1 class="invoice-spacing" v-if="showed == true">
                    {{ time }}
                  </h1>
                </b-col>

                <b-col cols="12" md="3">
                  <!-- type -->
                  <b-form-group label="Type" label-for="type">
                    <v-select
                      v-model="attendanceData.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="type"
                    />
                  </b-form-group>
                </b-col>

                <!-- Agent -->
                <b-col cols="12" md="3">
                  <validation-provider
                    #default="validationContext"
                    name="sku_agent"
                    rules="required"
                  >
                    <b-form-group
                      label="Agent / Engin"
                      label-for="sku_agent"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="attendanceData.sku_agent"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="agentOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="sku_agent"
                      />
                      <b-form-invalid-feedback>
                        Completer / Scanner le code/mattricule svp.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="3">
                  <!-- direction -->
                  <b-form-group label="Direction" label-for="direction">
                    <v-select
                      v-model="attendanceData.direction"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="directionOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="direction"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Spacer -->
              <hr class="invoice-spacing" v-if="showed == true" />
            </b-card>
          </b-col>
        </b-row>

        <b-row class="" v-if="actionIndex != 1">
          <b-col cols="12" md="3">
            <!-- created_at -->
            <b-form-group label="Date et Heure" label-for="created_at">
              <b-form-input
                id="created_at"
                v-model="attendanceData.created_at"
                trim
                class=""
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="attendanceData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// sweet alert
import { codeBasic } from "../../extensions/sweet-alert/code";

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,

  // Form Validation
  BFormInvalidFeedback,
} from "bootstrap-vue";

import vSelect from "vue-select";
import storeModule from "./storeModule";
import router from "@/router";
const moduleName = "attendance";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      errors: [],
      agentOptions: [],
      interval: null,
      time: null,
    };
  },
  props: {
    attendanceData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    // console
    if (router.currentRoute.name == "attendance-add-new") {
      this.operationTitle = "Nouvelle ";
      this.operationIcon = "AddIcon";
      this.editableRoute = true;
    }
    if (router.currentRoute.name == "attendance-view") {
      this.operationTitle = "Détails de ";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "attendance-edit") {
      this.operationTitle = "Modification du ";
      this.operationIcon = "EditIcon";
    }

    if (router.currentRoute.name == moduleName + "-add-new") {
      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == moduleName + "-edit") {
      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }

    // console.log(this.$refs);
    this.initTrHeight();

    // agentOptions
    // load agents list
    await axios
      .get(`agent`)
      .then((response) => {
        // default action
        var data = response.data.agents;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.agentOptions.push({
            label:
              data[i].firstname +
              " " +
              data[i].lastname +
              " " +
              data[i].surname,
            value: data[i].sku_agent,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    reinitForm() {
      var now = new Date();
      //
      this.$props.attendanceData.sku_attendance = "attendance-" + now.getTime();
      this.$props.attendanceData.sku_agent = "";
      this.$props.attendanceData.type = "";
      this.$props.attendanceData.method = "";
      this.$props.attendanceData.direction = "";
      // update row heigth
      this.initTrHeight();
    },

    async addUpdateAttendanceAction() {
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let url = "";
      if (router.currentRoute.name == "attendance-add-new") {
        this.actionName = "Enregistrement...";
        url = $themeConfig.app.api_endpoint + "add_" + moduleName;
      }
      if (router.currentRoute.name == "attendance-view") {
        url = $themeConfig.app.api_endpoint + "view_" + moduleName;
      }
      if (router.currentRoute.name == "attendance-edit") {
        this.actionName = "Modification...";
        url = $themeConfig.app.api_endpoint + "update_" + moduleName;
        // convert bool to int
      }

      // finalise form data
      // delete this.$props.moduleData.image_url;
      formData = this.$props.attendanceData;

      await axios
        .post(url, formData)
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "attendance-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          // console.log(error);

          this.actionName = defaultActionText;
          
          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    checkFormInputs() {
      var errors = 0;
      if (errors > 0) {
        return false;
      } else {
        return true;
      }
    },
    goToPrecAttendance() {
      router.push({ name: "attendance-view" });
    },
    goToNextAttendance() {
      router.push({ name: "attendance-list" });
    },
    goToList() {
      router.push({ name: "attendance-list" });
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form[0].scrollHeight);
      });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-attendance";
    var time = ref();
    setInterval(() => {
      time.value = new Date().toString("hh:mm:ss tt");
    }, 1000);

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetmoduleData = () => {
      this.$props.attendanceData = this.$props.moduleData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetmoduleData);

    //
    // let itemsList = ref([]);
    // store.dispatch("app-attendance/fetchAttendances").then((response) => {
    //   itemsList.value = response.data;
    //   // itemsList = response.data;
    // });

    // // fill items
    // let itemsOptions = itemsList;

    let directionOptions = [
      { label: "Entrée", value: "in" },
      { label: "Sortie", value: "out" },
      { label: "Non-précisé", value: "-" },
    ];

    let typeOptions = [
      { label: "Agent", value: "agent" },
      { label: "Visiteur", value: "visitor" },
      { label: "Vehicule du Bureau", value: "office-car" },
      { label: "Vehicule Agent", value: "agent-car" },
      { label: "Vehicule du Visiteur", value: "visitor-car" },
      { label: "Autres", value: "-" },
    ];

    return {
      time,
      refFormObserver,
      getValidationState,
      resetForm,
      directionOptions,
      typeOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
